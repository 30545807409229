import { Wallet, getWalletConnectConnector } from '@rainbow-me/rainbowkit';
export interface MyWalletOptions {
  projectId: string;
}
export const cdcDefi = ({ projectId }: MyWalletOptions): Wallet => ({
    id: 'f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d',
    name: 'Crypto.com | DeFi Wallet',
    iconUrl: 'https://explorer-api.walletconnect.com/v3/logo/lg/7c5ff577-a68d-49c5-02cd-3d83637b0b00?projectId=2f05ae7f1116030fde2d36508f472bfb',
    iconBackground: '#0c2f78',
    downloadUrls: {
      android: 'https://defi.onelink.me/pqA2',
      ios: 'https://defi.onelink.me/MFZQ',
      chrome: 'https://chrome.google.com/webstore/detail/cryptocom-wallet-extensio/hifafgmccdpekplomjjkcfgodnhcellj',
      qrCode: 'https://defi.onelink.me/MFZQ/download',
    },
    mobile: {
        getUri: (uri: string) => uri,
    },
    createConnector: getWalletConnectConnector({ projectId }),
});